<template>
  <div class="container-fluid">
    <breadcrumb>
      <img src="static/img/metric.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/operationaleffectiveness">Select Entity</router-link>
      </breadcrumb-item>
      <breadcrumb-item>
        <router-link to="../oeassessments"
          >Operational Effectiveness</router-link
        >
      </breadcrumb-item>
      <breadcrumb-item active
        >New Operational Effectiveness Assessment</breadcrumb-item
      >
    </breadcrumb>
    <div class="row">
      <div class="col-md-12">
        <form autocomplete="off">
          <card v-if="loaded">
            <div>
              <fg-input
                label="Control Framework"
                :error="getError('control framework')"
              >
                <el-select
                  name="control framework"
                  v-validate="modelValidations.controlFramework"
                  class="select-default"
                  style="width: 100%"
                  v-on:input="ddlControlFrameworkChange"
                  v-model="model.controlFrameworkId"
                  placeholder="Select Control Framework"
                >
                  <el-option
                    class="select-default"
                    v-for="item in controlFrameworks"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </fg-input>
              <fg-input label="Description">
                <textarea
                  name="Description"
                  v-model="model.description"
                  class="form-control"
                  style="height: 80px"
                ></textarea>
              </fg-input>
              <checkbox v-model="checkboxValue">Active</checkbox>
              <div class="text-right">
                <button
                  type="button"
                  @click="close"
                  class="btn btn-fill btn-danger btn-wd"
                >
                  <span class="btn-label">
                    <i class="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
                <button
                  type="submit"
                  @click.prevent="validate"
                  @click="save"
                  class="btn btn-fill btn-info btn-wd"
                >
                  <span class="btn-label">
                    <i class="fa fa-save"></i>
                  </span>
                  Save
                </button>
              </div>
            </div>
          </card>
        </form>

        <card v-if="loaded">
          <div>
            <div class="col-sm-12">
              <el-table
                v-loading="loading"
                style="width: 100%"
                :data="model.controlFrameworkCategories"
              >
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                ></el-table-column>
                <el-table-column :width="200" label="Weighting">
                  <template slot-scope="props">
                    <fg-input
                      name="weighting"
                      style="height: 25px"
                      :error="getError('weighting')"
                    >
                      <el-input-number
                        v-model="props.row.weighting"
                        :max="5"
                        :min="0"
                        v-validate="{ required: true, between: [0, 5] }"
                        name="weighting"
                      ></el-input-number>
                    </fg-input>
                  </template>
                </el-table-column>
                <el-table-column :width="200" label="Target Score">
                  <template slot-scope="props">
                    <fg-input
                      name="targetScore"
                      style="height: 25px"
                      :error="getError('targetScore')"
                    >
                      <el-input-number
                        v-model="props.row.targetScore"
                        :max="100"
                        :min="0"
                        v-validate="{ required: true, between: [0, 100] }"
                        name="targetScore"
                      ></el-input-number>
                    </fg-input>
                  </template>
                </el-table-column>
                <el-table-column :width="200" fixed="right" label="Assigned To">
                  <template slot-scope="props">
                    <fg-input
                      style="height: 25px"
                      :error="getError('assigned to')"
                    >
                      <el-select
                        name="assigned to"
                        v-validate="{ required: true }"
                        class="select-default"
                        style="width: 100%"
                        v-model="props.row.defaultManager"
                        v-on:input="ddlUserChange"
                        placeholder="Select User"
                      >
                        <el-option
                          class="select-default"
                          v-for="item in users"
                          :key="item.id"
                          :label="item.firstName + ' ' + item.lastName"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </fg-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Table, TableColumn, Select, Option, Tag } from "element-ui";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";
import $ from "jquery";

export default {
  components: {
    Checkbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Breadcrumb,
    BreadcrumbItem,
  },

  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        controlFrameworkId: null,
        description: "",
        controlFramework: "",
        isActive: true,
        organisationId: 0,
        controlFrameworkCategories: [],
      },
      controlFrameworks: [],
      modelValidations: {
        controlFramework: {
          required: true,
        },
      },
      tableColumns: [
        {
          prop: "name",
          label: "Category",
          minWidth: 300,
        },
      ],
      topSelect: false,
      users: [],
      userId: "",
      loading: true,
      loaded: false,
    };
  },
  created() {
    let self = this;
    self.getAllActiveControlFrameworks();
    //self.getAllActiveControlFrameworkCategories();
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.operationalEffectiveness, isValid);
      });
    },
    getAllActiveControlFrameworks() {
      let self = this;
      self.$store.state.services.controlFrameworkService
        .getAll({
          params: {
            ActiveControlFrameworks: true,
          },
        })
        .then((r) => {
          self.controlFrameworks = r.data;
          self.loading = false;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },

    getAllActiveUsers() {
      let self = this;
      self.$store.state.services.userService
        .getAll({
          params: {
            ActiveUsers: true,
          },
        })
        .then((r) => {
          self.users = r.data;
        })
        .catch((err) => console.log(err));
    },

    ddlControlFrameworkChange(value) {
      let self = this;
      self.model.controlFrameworkId = value;
      self.getAllActiveControlFrameworkCategories(value);
    },

    ddlUserChange(value) {
      let self = this;
      self.userId = value;
    },

    getAllActiveControlFrameworkCategories(value) {
      let self = this;
      self.$store.state.services.controlFrameworkCategoryService
        .getAll({
          params: {
            ActiveControlFrameworkCategories: true,
            ControlFrameworkId: value,
            AssessmentType: "OperationalEffectiveness",
          },
        })
        .then((r) => {
          self.model.controlFrameworkCategories = r.data;
          self.getAllActiveUsers();
        })
        .catch((err) => console.log(err));
    },

    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          self.model.isActive = self.checkboxValue.toString();
          self.model.organisationId = self.$route.params.organisationId;
          self.$store.state.services.oEControlAssessmentService
            .add(self.model)
            .then((r) => {
              self.notifyVue("top", "right", "success");
              self.close();
            })
            .catch((err) => console.log(err));
        }
      });
    },
    close() {
      let self = this;
      self.$router.push(
        "/operationaleffectiveness/" +
          self.$route.params.organisationId +
          "/oeassessments"
      );
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Operational Effectiveness Assessment created</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>